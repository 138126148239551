import Home from "./Views/Home/Home";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const App = () => {
	return (
		<Router>
			<Routes>
				<Route index element={<Home />} end />
			</Routes>
		</Router>
	);
};

export default App;
