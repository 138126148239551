// SCSS
import { useEffect } from "react";
import "./CarouselItem.scss";
import { useAppContext } from "../../Context/AppContext";
import { motion } from "framer-motion";

const CarouselItem = ({ data, index, isActive }) => {
	const { currentIndex, setCurrentIndex, setShowSidebar } = useAppContext();

	useEffect(() => {
		if (isActive) {
			setCurrentIndex(index);
		}
	}, [isActive, index]);

	// const activeThisItem = () => {
	//   if (block2IsOpen && block1IsOpen) {
	//     setBlock2IsOpen(false)
	//     setTimeout(() => {
	//       setBlock1IsOpen(false)
	//     }, 1000)
	//     setCurrentIndex(index)
	//   } else {
	//     setBlock1IsOpen(old => !old)
	//     setCurrentIndex(index)
	//   }

	// }

	// useEffect(() => {
	//   if (current) {
	//     setCurrentIndex(index)
	//   }
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [current])

	// return (
	//   <div className={`carousel_item_container ${current ? 'active' : ''} ${color === 'hamak' ? 'hamak' : 'mkmultimedia'}`} onClick={(e) => e.stopPropagation()}>
	//     <div className="image_container" onClick={() => activeThisItem()}>
	//       <img src={data.url_gif ? data.url_gif : data.url_poster} alt="" />
	//     </div>
	//     <p onClick={() => activeThisItem()}>{data.title}</p>
	//   </div>
	// )

	return (
		<motion.div
			initial={{ x: "-25%", opacity: 0.6 }}
			animate={isActive ? { x: "10%", opacity: 1 } : { x: "-25%", opacity: 0.6 }}
			exit={{ x: "-25%", opacity: 0.6 }}
			transition={{ duration: 0.6 }}
			className={`carousel_item_container ${isActive ? "active" : ""}`}
			onClick={(e) => e.stopPropagation()}
		>
			<div
				className="image_container"
				onClick={() => {
					setCurrentIndex(index);
					setShowSidebar((old) => !old);
				}}
			>
				<img src={data.url_gif ? data.url_gif : data.url_poster} alt="" />
			</div>
			<p
				onClick={() => {
					setCurrentIndex(index);
					setShowSidebar((old) => !old);
				}}
			>
				{data.title}
			</p>
		</motion.div>
	);
};

export default CarouselItem;
