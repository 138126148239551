import { createContext, useReducer, useEffect, useState, useContext } from "react";

export const AppContext = createContext();

// eslint-disable-next-line react/prop-types
export const AppContextProvider = ({ children }) => {
	const [loading, setLoading] = useState(true);

	const [currentIndex, setCurrentIndex] = useState(0);

	const [showSidebar, setShowSidebar] = useState(false);
	const [showIframe, setShowIframe] = useState(false);

	const [projectType, setProjectType] = useState("web"); // ["video", "digital", "3d"]

	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<AppContext.Provider
			value={{
				currentIndex,
				showSidebar,
				showIframe,
				projectType,
				setCurrentIndex,
				setShowSidebar,
				setShowIframe,
				setProjectType,
			}}
		>
			{loading ? <h3>Loading ...</h3> : children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => {
	const context = useContext(AppContext);

	if (!context) {
		throw Error("useAppContext must be used inside an AppContextProvider");
	}

	return context;
};
