import React, { useEffect, useState } from "react";

// SCSS
import "./Iframe.scss";

// ICONS
import { AiFillCloseCircle } from "react-icons/ai";
import Swiping from "../Swiping/Swiping";
import { useAppContext } from "../../Context/AppContext";
import { useDataContext } from "../../Context/DataContext";

const Iframe = () => {
	const { setShowIframe, projectType, showIframe, currentIndex } = useAppContext();
	const dataContext = useDataContext();
	const data =
		projectType === "video"
			? dataContext.videoData[currentIndex]
			: projectType === "other"
			? dataContext.otherData[currentIndex]
			: dataContext.webData[currentIndex];

	// const [dataFiltered, setDataFiltered] = useState({});

	// useEffect(() => {
	// 	if (data) {
	// 		setDataFiltered(data[currentIndex]);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [data]);

	// useEffect(() => {
	// 	if (data) {
	// 		setDataFiltered(data[currentIndex]);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [currentIndex]);

	// if (!dataFiltered) return <>Loading</>;

	return (
		// <div className="iframe_container">
		// 	<button onClick={() => setBlock2IsOpen(false)}>
		// 		<AiFillCloseCircle fill="black" />
		// 	</button>
		// 	{dataFiltered.url_iframe !== "" && block2IsOpen ? (
		// 		<iframe
		// 			title={dataFiltered?.title}
		// 			src={dataFiltered?.url_iframe}
		// 			frameBorder="0"
		// 			sandbox="allow-scripts allow-popups allow-forms"
		// 		></iframe>
		// 	) : (
		// 		<h3>No iframe</h3>
		// 	)}
		// </div>

		<div className="iframe_container">
			<button onClick={() => setShowIframe(false)}>
				<AiFillCloseCircle fill="black" />
			</button>
			<Swiping className="swiping-iframe" setOnSwipedRight={() => setShowIframe(false)}>
				{data?.url_iframe !== "" && showIframe ? (
					<iframe title={data?.title} src={data?.url_iframe} frameBorder="0"></iframe>
				) : (
					<h3>No iframe</h3>
				)}
			</Swiping>
		</div>
	);
};

export default Iframe;
