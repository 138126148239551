import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./reset.scss";
import { DataContextProvider } from "./Context/DataContext.jsx";
import { AppContextProvider } from "./Context/AppContext.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
	<DataContextProvider>
		<AppContextProvider>
			<App />
		</AppContextProvider>
	</DataContextProvider>
);
