import React from "react";
import { useSwipeable } from "react-swipeable";

import "./Swiping.scss";

const Swiping = ({ children, setOnSwipedLeft, setOnSwipedRight, className }) => {
	const handlers = useSwipeable({
		onSwipedLeft: setOnSwipedLeft, // After LEFT swipe  (SwipeEventData) => void
		onSwipedRight: setOnSwipedRight, // After RIGHT swipe (SwipeEventData) => void
	});

	return (
		<div {...handlers} className={className}>
			{children}
		</div>
	);
};

export default Swiping;
