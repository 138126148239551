import React, { useEffect, useState } from "react";

// SCSS
import "./Sidebar.scss";

// ICONS
import { AiFillCloseCircle } from "react-icons/ai";
import { ImNewTab } from "react-icons/im";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { motion } from "framer-motion";
import { useAppContext } from "../../Context/AppContext";
import { Swiper } from "swiper/react";
import { useDataContext } from "../../Context/DataContext";

// DATA

const Sidebar = () => {
	const { showSidebar, projectType, currentIndex, setShowIframe, setShowSidebar } = useAppContext();
	const dataContext = useDataContext();

	const data =
		projectType === "video"
			? dataContext.videoData[currentIndex]
			: projectType === "other"
			? dataContext.otherData[currentIndex]
			: dataContext.webData[currentIndex];

	if (!data) return null;
	return (
		// <div
		// 	className={`sidebar_item ${color === "hamak" ? "hamak" : "mkmultimedia"}`}
		// 	onClick={(e) => e.stopPropagation()}
		// >
		// 	<div className="sidebar_content">
		// 		<AiFillCloseCircle
		// 			className="icon_close"
		// 			onClick={() => {
		// 				setBlock2IsOpen(false);
		// 				setBlock1IsOpen(false);
		// 			}}
		// 		/>
		// 		<h1>{dataFiltered.title}</h1>
		// 		<ul className={dataFiltered.tags_techno?.length > 2 ? "tag_container" : "tag_container two"}>
		// 			{dataFiltered.tags_techno?.map((tag, index) => (
		// 				<li key={index}>{tag}</li>
		// 			))}
		// 		</ul>
		// 		{dataFiltered.url_video && block1IsOpen ? (
		// 			<VideoPlayer src={dataFiltered.url_video} isActive={block1IsOpen} />
		// 		) : (
		// 			<img id="imagePlayer" src={dataFiltered.url_poster || dataFiltered.url_gif} alt="" />
		// 		)}
		// 		<ul className="details_container">
		// 			<li>
		// 				<span>Client - </span> {dataFiltered.client}
		// 			</li>
		// 			{/* <li><span>Date de prod:</span> {dataFiltered.date_de_prod}</li> */}
		// 			<li>
		// 				<span>Agence - </span> {dataFiltered.agency}
		// 			</li>
		// 			{/* <li><span>Languages:</span> {dataFiltered.tags_lang?.map((item, index) => (<span className='lang'>{index + 1 === dataFiltered.tags_lang.length ? item : item + ', '}</span>))}</li> */}
		// 		</ul>
		// 		{dataFiltered.show_projetc_description && <p>{dataFiltered.description}</p>}
		// 		{/* {dataFiltered.show_projetc_cta && <input type="button" value="Voir le projet" target="_blank" onClick={() => setBlock2IsOpen(true)} />} */}
		// 		{dataFiltered.show_projetc_cta ? (
		// 			dataFiltered.open_in_target_blank ? (
		// 				<a href={dataFiltered.url_iframe} target={"_blank"} rel="noopener noreferrer">
		// 					Voir le projet <ImNewTab />
		// 				</a>
		// 			) : (
		// 				<input type="button" id="openIframe" value="Voir le projet" onClick={() => setBlock2IsOpen(true)} />
		// 			)
		// 		) : (
		// 			""
		// 		)}
		// 	</div>
		// </div>

		<div className="sidebar_container">
			<motion.div
				initial={{ x: "100%" }}
				animate={showSidebar ? { x: 0, visibility: "visible" } : { x: "100%", opacity: 0 }}
				exit={{ x: "100%" }}
				transition={{ duration: 0.5 }}
				className={"sidebar" + (showSidebar ? " active" : "")}
				onClick={(e) => e.stopPropagation()}
			>
				<h1>{data.title}</h1>

				<div className="content">
					<ul className={data.tags_techno?.length > 2 ? "tag_container" : "tag_container two"}>
						{data.tags_techno?.map((tag, index) => (
							<li key={index}>{tag}</li>
						))}
					</ul>
					{data.url_video && showSidebar ? (
						<VideoPlayer src={data.url_video} isActive={showSidebar} />
					) : (
						<img id="imagePlayer" src={data.url_poster || data.url_gif} alt="" />
					)}
					<ul className="details_container">
						<li>
							<span>Client - </span> {data.client}
						</li>
						{/* <li><span>Date de prod:</span> {dataFiltered.date_de_prod}</li> */}
						<li>
							<span>Agence - </span> {data.agency}
						</li>
						{/* <li><span>Languages:</span> {dataFiltered.tags_lang?.map((item, index) => (<span className='lang'>{index + 1 === dataFiltered.tags_lang.length ? item : item + ', '}</span>))}</li> */}
					</ul>
					{data.show_projetc_description && <p>{data.description}</p>}
					{/* {dataFiltered.show_projetc_cta && <input type="button" value="Voir le projet" target="_blank" onClick={() => setBlock2IsOpen(true)} />} */}

					{data.show_projetc_cta ? (
						data.open_in_target_blank ? (
							<a href={data?.url_iframe} target={"_blank"} rel="noopener noreferrer">
								Voir le projet <ImNewTab />
							</a>
						) : (
							<input type="button" id="openIframe" value="Voir le projet" onClick={() => setShowIframe(true)} />
						)
					) : (
						""
					)}
				</div>
			</motion.div>
		</div>
	);
};

export default Sidebar;
