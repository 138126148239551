import React, { useEffect, useRef, useState } from "react";

import "./VideoPlayer.scss";

const VideoPlayer = ({ src, isActive }) => {
	const [videoSrc, setVideoSrc] = useState(src);
	const videoRef = useRef(null);

	useEffect(() => {
		!isActive && videoRef.current.pause();
	}, [isActive]);

	useEffect(() => {
		setVideoSrc(src);
	}, [src]);

	return (
		<video className="videoPlayer" ref={videoRef} key={videoSrc} muted controls>
			<source src={videoSrc} type="video/mp4" />
		</video>
	);
};

export default VideoPlayer;
