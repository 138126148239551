import axios from "axios";
import { createContext, useReducer, useEffect, useState, useContext } from "react";

export const DataContext = createContext({
	error: null,
	loading: true,
	getData: () => {},
	videoData: null,
	webData: null,
	otherData: null,
});

// eslint-disable-next-line react/prop-types
export const DataContextProvider = ({ children }) => {
	const [error, setError] = useState(null);
	const [videoData, setVideoData] = useState(null);
	const [webData, setWebData] = useState(null);
	const [otherData, setOtherData] = useState(null);

	const getData = async () => {
		const response = await axios.get("https://www.agencehamak.fr/app-borne/data.json");
		// const response = await axios.get("/data.json");
		if (response.status === 200) {
			let vid = response.data.filter((item) => item.project_type === "video");
			let web = response.data.filter((item) => item.project_type === "digital");
			let other = response.data.filter((item) => item.project_type === "other");
			setVideoData(vid);
			setWebData(web);
			setOtherData(other);
			setLoading(false);
		} else {
			setError(response);
			setLoading(false);
		}
	};

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getData();
	}, []);

	return (
		<DataContext.Provider
			value={{
				error,
				loading,
				videoData,
				webData,
				otherData,
			}}
		>
			{loading ? <h3>Loading ...</h3> : children}
		</DataContext.Provider>
	);
};

export const useDataContext = () => {
	const context = useContext(DataContext);

	if (!context) {
		throw Error("useDataContext must be used inside an DataContextProvider");
	}

	return context;
};
