import React, { useEffect, useState } from "react";

// SCSS
import "./Home.scss";

// Components
import Carousel from "../../Components/Carousel/Carousel.jsx";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Iframe from "../../Components/Iframe/Iframe";

// LIBRARIES
//        react-full-screen
import axios from "axios";
import { useAppContext } from "../../Context/AppContext.jsx";
import { motion } from "framer-motion";
import Swiping from "../../Components/Swiping/Swiping.jsx";

const Home = ({ color }) => {
	const { showIframe, setShowSidebar, setShowIframe, setCurrentIndex, setProjectType, projectType } = useAppContext();

	return (
		// <div className="home" onClick={() => {}}>
		// 	<>
		// 		<Carousel
		// 			key="hamakVideo"
		// 			color={color}
		// 			data={isVideoDisplay ? hamakData.video : hamakData.digital}
		// 			setCurrentIndex={setCurrentIndex}
		// 			block1IsOpen={block1IsOpen}
		// 			block2IsOpen={block2IsOpen}
		// 			setBlock1IsOpen={setBlock1IsOpen}
		// 			setBlock2IsOpen={setBlock2IsOpen}
		// 		/>

		// 		<div
		// 			className={
		// 				block1IsOpen && block2IsOpen
		// 					? "side_container two"
		// 					: block1IsOpen && !block2IsOpen
		// 					? "side_container one"
		// 					: "side_container"
		// 			}
		// 			onClick={(e) => e.stopPropagation()}
		// 		>
		// 			<Sidebar
		// 				color={color}
		// 				data={isVideoDisplay ? hamakData.video : hamakData.digital}
		// 				currentIndex={currentIndex}
		// 				setCurrentIndex={setCurrentIndex}
		// 				block1IsOpen={block1IsOpen}
		// 				block2IsOpen={block2IsOpen}
		// 				setBlock1IsOpen={setBlock1IsOpen}
		// 				setBlock2IsOpen={setBlock2IsOpen}
		// 			/>
		// 			<Iframe
		// 				color={color}
		// 				data={isVideoDisplay ? hamakData.video : hamakData.digital}
		// 				currentIndex={currentIndex}
		// 				setCurrentIndex={setCurrentIndex}
		// 				block1IsOpen={block1IsOpen}
		// 				block2IsOpen={block2IsOpen}
		// 				setBlock1IsOpen={setBlock1IsOpen}
		// 				setBlock2IsOpen={setBlock2IsOpen}
		// 			/>
		// 		</div>
		// 	</>
		// 	{!block2IsOpen && (
		// 		<>
		// 			<input
		// 				type="button"
		// 				value="Vid"
		// 				className={isVideoDisplay ? "switch active" : "switch"}
		// 				onClick={() => {
		// 					setCurrentIndex(0);
		// 					setTimeout(() => {
		// 						setIsVideoDisplay(true);
		// 					}, 100);
		// 				}}
		// 			/>
		// 			<input
		// 				type="button"
		// 				value="Web"
		// 				className={!isVideoDisplay ? "switch two active" : "switch two"}
		// 				onClick={() => {
		// 					setCurrentIndex(0);
		// 					setTimeout(() => {
		// 						setIsVideoDisplay(false);
		// 					}, 100);
		// 				}}
		// 			/>
		// 		</>
		// 	)}
		// </div>
		<motion.div
			initial={{ x: "0" }}
			animate={showIframe ? { x: "-100vw" } : { x: "0" }}
			exit={{ x: "0" }}
			transition={{ type: "tween", duration: 0.5 }}
			className={"home_container" + (showIframe ? " active" : "")}
		>
			<div className="wrapper">
				<Carousel />
				<Swiping
					className="swiping-sidebar"
					setOnSwipedLeft={() => setShowSidebar(true)}
					setOnSwipedRight={() => setShowSidebar(false)}
				>
					<Sidebar />
				</Swiping>
			</div>
			<Iframe />
			<div className="button_container">
				<input
					type="button"
					value="3D"
					className={projectType === "other" ? "switch active" : "switch"}
					onClick={() => {
						setCurrentIndex(0);
						setTimeout(() => {
							setProjectType("other");
						}, 100);
					}}
				/>
				<input
					type="button"
					value="Vid"
					className={projectType === "video" ? "switch two active" : "switch two"}
					onClick={() => {
						setCurrentIndex(0);
						setTimeout(() => {
							setProjectType("video");
						}, 100);
					}}
				/>
				<input
					type="button"
					value="Web"
					className={projectType === "web" ? "switch three active" : "switch three"}
					onClick={() => {
						setCurrentIndex(0);
						setTimeout(() => {
							setProjectType("web");
						}, 100);
					}}
				/>
			</div>
		</motion.div>
	);
};

export default Home;
